import { statesChoices } from 'src/helpers/states-choice.helper'
import { IQuestion } from './../interfaces/question/question.interface'
import { Injectable } from '@angular/core'

export enum RegisterFormKey {
  REGISTER_FORM = 'registerForm',
}

export enum RegisterInputKeys {
  DISPLAY_NAME = 'displayName',
  DOCUMENT = 'document',
  STATE_REGISTRATION = 'stateRegistration',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'phone',
  COUNTRY = 'country',
  ZIP_CODE = 'zipCode',
  STREET = 'street',
  NUMBER = 'number',
  COMPLEMENT = 'complement',
  NEIGHBORHOOD = 'neighborhood',
  CITY = 'city',
  STATE = 'state',
  WEBSITE = 'website',
}

export enum SocialMediaFormKey {
  SOCIAL_MEDIA = 'socialMedia',
}

@Injectable({
  providedIn: 'root',
})
export class RegisterQuestions {
  constructor() {}

  questions: IQuestion[] = [
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.DISPLAY_NAME,
      title: '',
      placeholder: 'Name Company',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.DOCUMENT,
      title: '',
      placeholder: 'CNPJ',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      mask: 'CPF_CNPJ',
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.DOCUMENT,
      title: '',
      placeholder: 'State Registration',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.EMAIL,
      title: '',
      placeholder: 'Email',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      isEmail: true,
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.PASSWORD,
      title: '',
      placeholder: 'Password',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'textPassword',
      questionType: 'password',
      minLength: 6,
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.PHONE,
      title: '',
      placeholder: 'Cellphone',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      mask: '(00) 00000-0000',
      type: 'text',
      class: 'col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.COUNTRY,
      title: '',
      placeholder: 'Country',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.ZIP_CODE,
      title: '',
      placeholder: 'CEP',
      required: true,
      mask: '00000-000',
      pattern: /^\d{5}\-\d{3}$/,
      dropSpecialCharacters: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.STREET,
      title: '',
      placeholder: 'Street',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.NUMBER,
      title: '',
      placeholder: 'Number',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      questionType: 'number',
      class: 'col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.COMPLEMENT,
      title: '',
      placeholder: 'Complement',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.NEIGHBORHOOD,
      title: '',
      placeholder: 'Neighborhood',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.CITY,
      title: '',
      placeholder: 'City',
      required: false,
      value: '',
      isDisabled: true,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.STATE,
      title: '',
      placeholder: 'State',
      required: false,
      value: '',
      isDisabled: true,
      response: {
        value: '',
      },
      type: 'select',
      choices: statesChoices,
      class: 'col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4',
    },
    {
      formKey: RegisterFormKey.REGISTER_FORM,
      key: RegisterInputKeys.WEBSITE,
      title: '',
      placeholder: 'Website',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12',
    },
  ]

  socialMedia: IQuestion[] = [
    {
      formKey: SocialMediaFormKey.SOCIAL_MEDIA,
      key: 'name-1',
      title: '',
      placeholder: 'Social Media',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'select',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
      choices: [
        {
          title: 'Select a social Media',
          value: '',
        },
        {
          title: 'Tiktok',
          value: 'TIKTOK',
        },
        {
          title: 'Instagram',
          value: 'INSTAGRAM',
        },
        {
          title: 'Twitter',
          value: 'TWITTER',
        },
        {
          title: 'Facebook',
          value: 'FACEBOOK',
        },
        {
          title: 'Linkedin',
          value: 'LINKEDIN',
        },
        {
          title: 'Youtube',
          value: 'YOUTUBE',
        },
        {
          title: 'Others',
          value: 'OTHERS',
        },
      ],
    },
    {
      formKey: SocialMediaFormKey.SOCIAL_MEDIA,
      key: 'link-1',
      title: '',
      placeholder: 'Link',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
  ]

  imutableSocialMedia: IQuestion[] = [
    {
      formKey: SocialMediaFormKey.SOCIAL_MEDIA,
      key: 'name-1',
      title: '',
      placeholder: 'Social Media',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'select',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
      choices: [
        {
          title: 'Select a social Media',
          value: '',
        },
        {
          title: 'Tiktok',
          value: 'TIKTOK',
        },
        {
          title: 'Instagram',
          value: 'INSTAGRAM',
        },
        {
          title: 'Twitter',
          value: 'TWITTER',
        },
        {
          title: 'Facebook',
          value: 'FACEBOOK',
        },
        {
          title: 'Linkedin',
          value: 'LINKEDIN',
        },
        {
          title: 'Youtube',
          value: 'YOUTUBE',
        },
        {
          title: 'Others',
          value: 'OTHERS',
        },
      ],
    },
    {
      formKey: SocialMediaFormKey.SOCIAL_MEDIA,
      key: 'link-1',
      title: '',
      placeholder: 'Link',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6',
    },
  ]
}
